import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavigationItems } from './NavigationItems';

const NavigationDrawerRoot = 'velo-navigation-drawer';

const NavigationItemGroupMapping = {
  DEFAULT: 0,
  OPERATING_AS: 1,
  SIGNOUT: 2,
  FOOTER: 3,
};

/**
 * Navigation labels
 */
const NavigationItemLabels = {
  [NavigationItems.PAYMENTS]: <FormattedMessage defaultMessage="Payments" />,
  [NavigationItems.PAYORS]: <FormattedMessage defaultMessage="Payors" />,
  [NavigationItems.POOLED_ACCOUNTS]: (
    <FormattedMessage defaultMessage="FBO Accounts" />
  ),
  [NavigationItems.ADMINS]: <FormattedMessage defaultMessage="Admins" />,
  [NavigationItems.CHANNELS]: (
    <FormattedMessage defaultMessage="Payment Methods" />
  ),
  [NavigationItems.PROFILE]: <FormattedMessage defaultMessage="Profile" />,
  [NavigationItems.PROFILE_DEFAULT]: (
    <FormattedMessage defaultMessage="Details" />
  ),
  [NavigationItems.PROFILE_COMPANY_DETAILS]: (
    <FormattedMessage defaultMessage="Company Details" />
  ),
  [NavigationItems.PROFILE_MY_DETAILS]: (
    <FormattedMessage defaultMessage="My Details" />
  ),
  [NavigationItems.PROFILE_SECURITY]: (
    <FormattedMessage defaultMessage="Security" />
  ),
  [NavigationItems.PROFILE_COMMUNICATIONS]: (
    <FormattedMessage defaultMessage="Communications" />
  ),
  [NavigationItems.PROFILE_COMPANY_USERS]: (
    <FormattedMessage defaultMessage="Users" />
  ),
  [NavigationItems.SIGNOUT]: <FormattedMessage defaultMessage="Sign Out" />,
  [NavigationItems.CONTACT]: <FormattedMessage defaultMessage="Contact Us" />,
  [NavigationItems.TERMS]: <FormattedMessage defaultMessage="Terms of Use" />,
  [NavigationItems.PRIVACY]: (
    <FormattedMessage defaultMessage="Privacy Policy" />
  ),
  [NavigationItems.FUNDING]: <FormattedMessage defaultMessage="Funding" />,
  [NavigationItems.FUNDINGS]: <FormattedMessage defaultMessage="Funding" />,
  [NavigationItems.PAYOUTS]: <FormattedMessage defaultMessage="Payouts" />,
  [NavigationItems.PAYEES]: <FormattedMessage defaultMessage="Payees" />,
  [NavigationItems.PAYEE_USERS]: (
    <FormattedMessage defaultMessage="Company Payee Users" />
  ),
  [NavigationItems.USERS]: <FormattedMessage defaultMessage="Payor Users" />,
  [NavigationItems.OPERATING_AS]: (
    <FormattedMessage defaultMessage="Acting as" />
  ),
  [NavigationItems.SOURCE_ACCOUNTS]: (
    <FormattedMessage defaultMessage="Source Accounts" />
  ),
  [NavigationItems.FUNDING_ACCOUNTS]: (
    <FormattedMessage defaultMessage="Funding Accounts" />
  ),
  [NavigationItems.REPORTS]: <FormattedMessage defaultMessage="Reports" />,
  [NavigationItems.SETTINGS]: <FormattedMessage defaultMessage="Settings" />,
  [NavigationItems.WEBHOOK]: <FormattedMessage defaultMessage="Webhook" />,

  [NavigationItems.PLATFORM_SECTION]: (
    <FormattedMessage defaultMessage="Platform" />
  ),
  [NavigationItems.PAYOR_SECTION]: <FormattedMessage defaultMessage="Payor" />,
  [NavigationItems.PAYEE_SECTION]: <FormattedMessage defaultMessage="Payee" />,
  [NavigationItems.DASHBOARD]: <FormattedMessage defaultMessage="Home" />,
  [NavigationItems.PAYMENT_REQUESTS]: (
    <FormattedMessage defaultMessage="Requests" />
  ),
  [NavigationItems.PAYIN_HISTORY]: (
    <FormattedMessage defaultMessage="Payments" />
  ),
  [NavigationItems.CREDIT_PURCHASE_HISTORY]: (
    <FormattedMessage defaultMessage="Credits" />
  ),
  [NavigationItems.CREDITOR_USERS]: <FormattedMessage defaultMessage="Users" />,
  [NavigationItems.PROFILE_SETTINGS]: (
    <FormattedMessage defaultMessage="Profile" />
  ),
  [NavigationItems.GLOBAL_SETTINGS]: (
    <FormattedMessage defaultMessage="Global Settings" />
  ),
  [NavigationItems.MY_KIOSK]: <FormattedMessage defaultMessage="Kiosk" />,
  [NavigationItems.CREDITOR_ACCOUNTS]: (
    <FormattedMessage defaultMessage="User accounts" />
  ),
  [NavigationItems.EMAIL_TEMPLATES]: (
    <FormattedMessage defaultMessage="Email Templates" />
  ),
};

/**
 * Navigation Icons
 */
const NavigationItemIcons = {
  [NavigationItems.PROFILE]: {
    icon: 'account_circle',
  },
  [NavigationItems.PROFILE_DEFAULT]: {
    icon: 'person',
  },
  [NavigationItems.PROFILE_COMPANY_DETAILS]: {
    icon: 'payors',
  },
  [NavigationItems.PROFILE_SECURITY]: {
    icon: 'lock',
  },
  [NavigationItems.PROFILE_MY_DETAILS]: {
    icon: 'lock',
  },
  [NavigationItems.PROFILE_COMMUNICATIONS]: {
    icon: 'drafts',
  },
  [NavigationItems.PROFILE_COMPANY_USERS]: {
    icon: 'users',
  },
  [NavigationItems.CHANNELS]: {
    icon: 'payment_methods',
  },
  [NavigationItems.PAYORS]: {
    icon: 'payors',
  },
  [NavigationItems.PAYOR_SECTION]: {
    icon: 'payors',
  },
  [NavigationItems.PAYMENTS]: {
    icon: 'payments',
  },
  [NavigationItems.FUNDING]: {
    icon: 'funding',
  },
  [NavigationItems.FUNDINGS]: {
    icon: 'funding',
  },
  [NavigationItems.USERS]: {
    icon: 'users',
  },
  [NavigationItems.PAYEES]: {
    icon: 'payees',
  },
  [NavigationItems.PAYEE_SECTION]: {
    icon: 'payees',
  },
  [NavigationItems.PAYEE_USERS]: {
    icon: 'users',
  },
  [NavigationItems.PAYOUTS]: {
    icon: 'payouts',
  },
  [NavigationItems.POOLED_ACCOUNTS]: {
    icon: 'accounts',
  },
  [NavigationItems.ADMINS]: {
    icon: 'admins',
  },
  [NavigationItems.SIGNOUT]: {
    icon: 'sign_out',
  },
  [NavigationItems.TERMS]: { icon: 'help' },
  [NavigationItems.OPERATING_AS]: {
    icon: 'operating_as',
  },
  [NavigationItems.SOURCE_ACCOUNTS]: {
    icon: 'accounts',
  },
  [NavigationItems.FUNDING_ACCOUNTS]: {
    icon: 'funding_accounts',
  },
  [NavigationItems.REPORTS]: {
    icon: 'reports',
  },
  [NavigationItems.SETTINGS]: {
    icon: 'settings',
  },
  [NavigationItems.WEBHOOK]: {
    icon: 'webhooks',
  },
  [NavigationItems.PLATFORM_SECTION]: {
    icon: 'platform',
  },
  [NavigationItems.DASHBOARD]: {
    icon: 'dashboard',
  },
  [NavigationItems.PAYMENT_REQUESTS]: {
    icon: 'payment_request',
  },
  [NavigationItems.PAYIN_HISTORY]: {
    icon: 'pay_ins',
  },
  [NavigationItems.CREDIT_PURCHASE_HISTORY]: {
    icon: 'credit_purchase',
  },
  [NavigationItems.CREDITOR_USERS]: {
    icon: 'users',
  },
  [NavigationItems.PROFILE_SETTINGS]: {
    icon: 'person',
  },
  [NavigationItems.GLOBAL_SETTINGS]: {
    icon: 'platform',
  },
  [NavigationItems.MY_KIOSK]: {
    // TODO: Replace with new DS icon once DS is re-published
    icon: 'payments',
  },
  [NavigationItems.CREDITOR_ACCOUNTS]: {
    icon: 'users',
  },
  [NavigationItems.EMAIL_TEMPLATES]: {
    icon: 'drafts',
  },
};

const navigationDrawerConfig = {
  NavigationDrawerRoot,
  NavigationItemGroupMapping,
  NavigationItemLabels,
  NavigationItemIcons,
};

export { navigationDrawerConfig };
