import React from 'react';
import { useHistory } from 'react-router';
import { TokenSizeBreakpointsXs } from '@design-system/tokens/layout/js/breakpoint-tokens.module';
import { NavigationDrawer } from 'velo-react-components';
import {
  useAppContext,
  useLoggedInUser,
  buildNavigationItem,
  convertLegalLinksToNavItems,
  useWireframe,
  ApplicationVariantEnum,
  buildMapFromOrderedKeys,
  useSelectedNavItem,
  useLegalLinks,
} from 'velo-portal-common';
import { usePayorHierarchySelector } from 'velo-portal-common';

const subMenuItems = {
  [NavigationDrawer.Items.SETTINGS]: [NavigationDrawer.Items.WEBHOOK],
};

function useNavigation() {
  const primaryPayorId = useLoggedInUser();
  const operateAsPayors = usePayorHierarchySelector(primaryPayorId);
  const {
    navigateToFundingList,
    navigateToUsersList,
    navigateToPayeesList,
    navigateToSourceAccountsList,
    navigateToFundingAccountsList,
    navigateToReports,
    navigateToPaymentsList,
    navigateToPayoutsList,
    navigateToSignout,
    navigateToSettingsWebhook,
  } = useWireframe(useHistory());

  const legalLinks = useLegalLinks(ApplicationVariantEnum.PayorPortal);

  const routes = {
    [NavigationDrawer.Items.SOURCE_ACCOUNTS]: navigateToSourceAccountsList.path,
    [NavigationDrawer.Items.FUNDING_ACCOUNTS]:
      navigateToFundingAccountsList.path,
    [NavigationDrawer.Items.FUNDING]: navigateToFundingList.path,
    [NavigationDrawer.Items.PAYOUTS]: navigateToPayoutsList.path,
    [NavigationDrawer.Items.PAYMENTS]: navigateToPaymentsList.path,
    [NavigationDrawer.Items.PAYEES]: navigateToPayeesList.path,
    [NavigationDrawer.Items.USERS]: navigateToUsersList.path,
    [NavigationDrawer.Items.REPORTS]: navigateToReports.path,
    [NavigationDrawer.Items.SETTINGS]: '/settings',
    [NavigationDrawer.Items.WEBHOOK]: navigateToSettingsWebhook.path,
    [NavigationDrawer.Items.OPERATING_AS]: null,
    [NavigationDrawer.Items.SIGNOUT]: navigateToSignout.path,
    ...legalLinks,
  };

  const orders = buildMapFromOrderedKeys([
    NavigationDrawer.Items.PAYOUTS,
    NavigationDrawer.Items.PAYMENTS,
    NavigationDrawer.Items.PAYEES,
    NavigationDrawer.Items.SOURCE_ACCOUNTS,
    NavigationDrawer.Items.FUNDING_ACCOUNTS,
    NavigationDrawer.Items.FUNDING,
    NavigationDrawer.Items.USERS,
    NavigationDrawer.Items.REPORTS,
    NavigationDrawer.Items.SETTINGS,
    NavigationDrawer.Items.OPERATING_AS,
    NavigationDrawer.Items.SIGNOUT,
    NavigationDrawer.Items.TERMS,
    NavigationDrawer.Items.PRIVACY,
    NavigationDrawer.Items.CONTACT,
  ]);

  const data = {
    routes,
    orders,
    subMenuItems,
    labels: NavigationDrawer.config.NavigationItemLabels,
  };

  return {
    navItems: [
      buildNavigationItem({
        id: NavigationDrawer.Items.SOURCE_ACCOUNTS,
        group: NavigationDrawer.config.NavigationItemGroupMapping.DEFAULT,
        ...data,
      }),
      buildNavigationItem({
        id: NavigationDrawer.Items.FUNDING_ACCOUNTS,
        group: NavigationDrawer.config.NavigationItemGroupMapping.DEFAULT,
        ...data,
      }),
      buildNavigationItem({
        id: NavigationDrawer.Items.FUNDING,
        group: NavigationDrawer.config.NavigationItemGroupMapping.DEFAULT,
        ...data,
      }),
      buildNavigationItem({
        id: NavigationDrawer.Items.PAYOUTS,
        group: NavigationDrawer.config.NavigationItemGroupMapping.DEFAULT,
        ...data,
      }),
      buildNavigationItem({
        id: NavigationDrawer.Items.PAYMENTS,
        group: NavigationDrawer.config.NavigationItemGroupMapping.DEFAULT,
        ...data,
      }),
      buildNavigationItem({
        id: NavigationDrawer.Items.PAYEES,
        group: NavigationDrawer.config.NavigationItemGroupMapping.DEFAULT,
        ...data,
      }),
      buildNavigationItem({
        id: NavigationDrawer.Items.USERS,
        group: NavigationDrawer.config.NavigationItemGroupMapping.DEFAULT,
        ...data,
      }),
      buildNavigationItem({
        id: NavigationDrawer.Items.REPORTS,
        group: NavigationDrawer.config.NavigationItemGroupMapping.DEFAULT,
        ...data,
      }),
      buildNavigationItem({
        id: NavigationDrawer.Items.SETTINGS,
        group: NavigationDrawer.config.NavigationItemGroupMapping.DEFAULT,
        ...data,
      }),
      buildNavigationItem({
        id: NavigationDrawer.Items.OPERATING_AS,
        group: NavigationDrawer.config.NavigationItemGroupMapping.OPERATING_AS,
        dividers: { top: true, bottom: false },
        ...data,
      }),
      buildNavigationItem({
        id: NavigationDrawer.Items.SIGNOUT,
        group: NavigationDrawer.config.NavigationItemGroupMapping.SIGNOUT,
        dividers: { top: true, bottom: true },
        ...data,
      }),
      ...convertLegalLinksToNavItems(
        legalLinks,
        orders,
        NavigationDrawer.config.NavigationItemLabels,
        NavigationDrawer.config.NavigationItemGroupMapping.FOOTER
      ),
    ].sort((a, b) => a.order - b.order),
    routes,
    breakpoint: TokenSizeBreakpointsXs,
    operateAsPayors,
    primaryPayorId,
  };
}

function AppNavigationBase({
  history,
  resetToBaseRoute,
  routes,
  breakpoint,
  navItems,
  operateAsPayors,
  primaryPayorId,
  children,
}) {
  const { logout, setOperatingUser, operatingUser } = useAppContext();
  const [selectedItem] = useSelectedNavItem(navItems);

  const handleChangeOperatingAs = (operateAsPayorId) => {
    setOperatingUser(operateAsPayorId);
    resetToBaseRoute();
  };

  // need to make a special case for logout here
  const onNavigation = (id, newTab) => {
    if (id === NavigationDrawer.Items.SIGNOUT) {
      //sign out action
      logout({ userInitiatedLogout: true });
    } else if (newTab) {
      //opens in a new tab window
      window.open(routes[id], '_blank');
      document.activeElement.blur();
    } else {
      //standard re-route.
      history.push(routes[id]);
    }
  };

  return (
    <NavigationDrawer
      onNavigation={onNavigation}
      navItems={navItems}
      breakpoint={breakpoint}
      selectedItem={selectedItem}
      handleChangeOperatingAs={handleChangeOperatingAs}
      operateAsPayors={operateAsPayors || []}
      primaryPayorId={primaryPayorId}
      operateAsPayorId={operatingUser || primaryPayorId}
    >
      {children}
    </NavigationDrawer>
  );
}

const AppNavigation = ({ children, ...props }) => {
  const navProps = useNavigation();

  return (
    <AppNavigationBase {...props} {...navProps}>
      {children}
    </AppNavigationBase>
  );
};

export { AppNavigation };
