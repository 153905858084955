/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { bool, string, func, shape, number } from 'prop-types';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { VeloList } from '../../VeloList';
import { VeloTypography } from '../../VeloTypography';
import { NavigationDrawerNestedMenu } from '../NavigationDrawerNestedMenu';
import { navigationDrawerConfig } from '../navigationDrawerConfig';
import { dataTestIdBuilder } from 'velo-data';

import navigationDrawerPropTypes from '../navigationDrawerPropTypes';
import { getDataTip } from '../helpers';

import styles from './NavigationDrawerItem.module.scss';

const root = `velo-navigation-drawer-item`;

const TestIds = {
  DIVIDER: `${root}-divider`,
  RIGHT_ALIGNED_ACTION: `${root}-right-aligned-action`,
};

NavigationDrawerItem.testIds = TestIds;
NavigationDrawerItem.root = root;

NavigationDrawerItem.propTypes = {
  /** Render if open. */
  isNavigationDrawerOpen: bool,
  /** the index of item in the list */
  index: number,
  /** is the currently selected item */
  isSelectedItem: bool,
  /** the item to be rendered */
  item: navigationDrawerPropTypes.navigationItem,
  /** Optional type of the notification icon */
  notificationIcon: string,
  /** optional custom onClick function*/
  onClick: func,
  /** Optional classname  to be applied */
  className: string,
  /** optional right aligned action configuration */
  rightAlignedAction: shape({
    icon: string,
    onClick: func,
  }),
};

export function NavigationDrawerItem({
  item,
  index,
  isSelectedItem,
  isNavigationDrawerOpen,
  notificationIcon,
  rightAlignedAction,
  selectedSubMenuParent,
  isMobile,
  subNavOpenId,
  selectedSubMenuItemId,
  selectedSubMenuParentId,
  hideTooltip,
  onClick,
}) {
  const intl = useIntl();
  const parentSubMenuId = selectedSubMenuParent && selectedSubMenuParent.id;

  const showToolTip =
    !item.openInNewTab &&
    !item.legalLinksPrimaryItem &&
    !isNavigationDrawerOpen;

  const icon = navigationDrawerConfig.NavigationItemIcons[item.id];

  const subMenuItemsOpen =
    item.subMenuItems &&
    !!subNavOpenId &&
    isNavigationDrawerOpen &&
    !isMobile &&
    !item.legalLinksPrimaryItem &&
    parentSubMenuId === item.id;

  // Whether the item is the current active item - can be either main menu or a submenu item
  const isActivated =
    (isSelectedItem &&
      (!selectedSubMenuItemId || !isNavigationDrawerOpen || isMobile)) ||
    (!subMenuItemsOpen && selectedSubMenuParentId === item.id);

  const hasSubItems = item.subMenuItems && !item.legalLinksPrimaryItem;

  const fullScreenProps = hasSubItems && {
    metaIcon: !isMobile ? 'chevron_down' : 'chevron_right',
  };

  const label = getDataTip(intl, item.label);

  const handle = (
    <VeloList.SimpleItem
      {...fullScreenProps}
      graphic={icon}
      data-nav-id={item.id}
      id={`velo-navigation-item-${index}`}
      onMouseOver={(evt) => {
        hideTooltip(false);
      }}
      text={
        <VeloTypography
          use={isSelectedItem ? 'navigationTextActive' : 'navigationText'}
          className={classnames(styles.label, {
            [styles.fadeoutOnExpand]: item.subMenuItems !== undefined,
            [styles.fadeinOnExpand]: item.subMenuItems !== undefined,
          })}
        >
          {item.label}
        </VeloTypography>
      }
      activated={isActivated}
      className={classnames(styles.item, styles[item.id], {
        [styles.collapsed]: !isNavigationDrawerOpen,
        [styles.legalLinksPrimaryItem]: item.legalLinksPrimaryItem,
        [styles.noicon]: !icon,
        [styles.parent]: item.subMenuItems,
        [styles.activated]: subMenuItemsOpen,
        [styles.closed]: !subMenuItemsOpen,
      })}
      data-tip={showToolTip ? label : undefined}
      data-testid={dataTestIdBuilder(root, label, 'link')}
    >
      {notificationIcon && (
        <span
          data-testid={dataTestIdBuilder(
            root,
            label,
            `${notificationIcon}-indicator`
          )}
          className={classnames(styles.indicator, styles[notificationIcon])}
        />
      )}

      {rightAlignedAction && (
        <VeloList.ItemGraphic
          data-testid={dataTestIdBuilder(root, label, 'more')}
          className={styles.rightSideIcon}
          icon={{
            icon: rightAlignedAction.icon,
            tabIndex: -1,
            focusable: false,
          }}
        />
      )}
    </VeloList.SimpleItem>
  );

  return (
    <>
      {item.hasDividers && item.hasDividers.top && <VeloList.Divider />}

      <VeloList.Collapsible handle={handle} open={subNavOpenId === item.id}>
        {!isMobile && hasSubItems && !item.isLegalLinksPrimaryItem && (
          <NavigationDrawerNestedMenu
            subMenuItems={item.subMenuItems}
            selectedSubMenuItemId={selectedSubMenuItemId}
            isMobile={isMobile}
          />
        )}
      </VeloList.Collapsible>

      {item.hasDividers && item.hasDividers.bottom && <VeloList.Divider />}
    </>
  );
}
