import fromPromise from 'callbag-from-promise';
import callbagOf from 'callbag-of';
import callbagMap from 'callbag-map';
import flatten from 'callbag-flatten';
import take from 'callbag-take';
import throwError from 'callbag-throw-error';
import pipe from 'callbag-pipe';
import rescue from 'callbag-rescue';
import template from 'just-template';
import callbagFromFetch from './callbagFromFetch';
import { SendError } from '../SendError';
import { errorsByCode } from '../errorTypes';

export const defaultErrorMessage =
  'Sorry, something went wrong. Please try again.';

const defaultContentType = 'application/json; charset=UTF-8';

export const contentTypesByName = {
  JSON: 'application/json',
  HAL_JSON: 'application/hal+json',
  FORM_ENCODED: 'application/x-www-form-urlencoded',
  MULTI_PART: 'multipart/form-data',
};

function isJsonResponse(response) {
  var contentType = response.headers.get('content-type') || '';
  return (
    contentType.indexOf(contentTypesByName.JSON) === 0 ||
    contentType.indexOf(contentTypesByName.HAL_JSON) === 0
  );
}

function createResponseCallbag(value) {
  if (value instanceof Error) return throwError(value);
  return callbagOf(value);
}

function headersToData(response) {
  const location = response.headers.get('location') || null;

  // convert seconds to milliseconds for timers etc
  const retryAfter = parseInt(response.headers.get('Retry-After') || 0) * 1000;
  return { location, ...(retryAfter > 0 && { retryAfter }) };
}

function responseCallbagFromFetch(response) {
  const status = response.status;
  const errorMessage = errorsByCode[status];

  switch (status) {
    case 202:
    case 204: {
      return createResponseCallbag(headersToData(response));
    }
    case 201: {
      const headerData = headersToData(response);

      return pipe(
        fromPromise(response.json()),
        rescue((error) => callbagOf({})),
        callbagMap((data) => createResponseCallbag({ ...headerData, ...data })),
        flatten
      );
    }
    case 401:
    case 403: {
      return createResponseCallbag(new SendError(status, [{ errorMessage }]));
    }

    case 500:
    case 501:
    case 502:
    case 503:
    case 504: {
      return createResponseCallbag(
        new SendError(status, [{ errorMessage: defaultErrorMessage }])
      );
    }

    default: {
      const resolve = status === 200;
      if (isJsonResponse(response)) {
        return pipe(
          fromPromise(response.json()),
          callbagMap((data) => {
            let options = {
              legacyAuthError:
                data.error_description && !data.errors
                  ? data.error_description
                  : undefined,
            };
            return createResponseCallbag(
              resolve
                ? data
                : new SendError(
                    status,
                    data.errors,
                    data.correlationId,
                    options
                  )
            );
          }),
          flatten
        );
      }

      return pipe(
        fromPromise(response.text()),
        callbagMap((text) =>
          createResponseCallbag(
            resolve ? text : new SendError(status, [{ errorMessage: text }])
          )
        ),
        flatten
      );
    }
  }
}

function pathToHandlebars(path) {
  return path.replace(/\{(\w+)\}/g, (str) => `{${str}}`);
}

function queryObjectToString(query) {
  if (query) {
    return (
      '?' +
      new URLSearchParams(
        // filter out values which have been set as undefined
        Object.entries(query).reduce(
          (map, [k, v]) => ({ ...map, ...(v !== undefined && { [k]: v }) }),
          {}
        )
      ).toString()
    );
  }
  return '';
}

function authByType(type, { access_token, basic }) {
  if (type === 'basic') {
    return `Basic ${basic}`;
  }

  return `Bearer ${access_token}`;
}

function createFetchObservableFactory(
  {
    path,
    type: method,
    pathParameters,
    query,
    body,
    auth,
    headers: requestHeaders,
    contentType = defaultContentType,
  },
  host
) {
  return (authState) => {
    // Checks for missing Path parameters avoids unnecessary api calls, and 403 error log out
    const paramMissing = Object.values(pathParameters).some(
      (value) => value === undefined
    );
    if (paramMissing) {
      return throwError(new Error('Path Parameter missing'));
    }
    const url =
      authState.host +
      template(pathToHandlebars(path), pathParameters) +
      queryObjectToString(query);
    const headers = {
      ...(auth && { Authorization: authByType(auth, authState) }),
      'Content-Type': contentType,
      'Access-Control-Request-Headers': 'Location',
      ...requestHeaders,
    };

    let requestBody = undefined;
    if (contentType.indexOf(contentTypesByName.FORM_ENCODED) > -1) {
      requestBody = new URLSearchParams(body);
    } else if (contentType.indexOf(contentTypesByName.MULTI_PART) > -1) {
      /**
       * For some reason, this is required
       * @see https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
       */
      delete headers['Content-Type'];
      requestBody = new FormData();
      Object.entries(body).forEach((value) => {
        requestBody.append(value[0], value[1]);
      });
    } else {
      requestBody = JSON.stringify(body);
    }

    return pipe(
      callbagFromFetch(url, {
        method,
        headers,
        body: requestBody,
      }),
      // Fetch will only error if the network is unavailable.
      rescue((error) =>
        throwError(new SendError(0, [{ errorMessage: defaultErrorMessage }]))
      )
    );
  };
}

export function createSendWithAjax(sessionStateObservable) {
  return (spec) =>
    pipe(
      sessionStateObservable,
      take(1),
      callbagMap(createFetchObservableFactory(spec)),
      flatten,
      callbagMap(responseCallbagFromFetch),
      flatten
    );
}
